import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout/Layout';

import ParagraphBlockElement from '../components/layout/ParagraphBlockElement';
import { ScrollContainer } from '../context/scrollBehavior';
import useAuth from '../util/hooks/useAuth';
import HeroHome from '../components/layout/hero/HeroHome';

const HomeLandingPage = ({ data }) => {
  useAuth();

  const cms = data.site.siteMetadata.siteUrl;

  const [device, setDevice] = useState('mobile');

  const landingPage = data.DrupalParagraphs.edges[0].node;
  const { title, field_json_api_call_data } = landingPage;

  // Random contemporary hero
  const cont_hero = data.hero_images.nodes.filter(
    (image) =>
      image.relationships.field_artist_ref?.relationships.field_artist_type
        .name === 'Established living contemporary'
  );
  const cont_random = Math.floor(
    Math.random() * (cont_hero.length - 1 - 0 + 1) + 0
  );

  // // Random history hero
  const history_hero = data.hero_images.nodes.filter(
    (image) =>
      image.relationships.field_artist_ref?.relationships.field_artist_type
        .name === 'Historical works of significance'
  );
  const history_random = Math.floor(
    Math.random() * (history_hero.length - 1 - 0 + 1) + 0
  );

  return (
    <Layout
      device={device}
      setDevice={setDevice}
      logo={data.logo}
      menu={data.allMenuMenuLink}
      footerMenu={data.footer_menu}
      locations={data.locations.edges}
    >
      {cont_hero[cont_random] || history_hero[history_random] ? (
        <ScrollContainer force={true}>
          <section className="grid-container">
            <HeroHome
              dataOne={cont_hero[cont_random]}
              titleOne={`Contemporary`}
              pathOne={`/contemporary/`}
              dataTwo={history_hero[history_random]}
              titleTwo={`Historical`}
              pathTwo={`/historical/`}
              device={device}
            />
          </section>
        </ScrollContainer>
      ) : (
        <></>
      )}

      <ParagraphBlockElement
        blocks={field_json_api_call_data}
        device={device}
        title={title}
      />
    </Layout>
  );
};

export default HomeLandingPage;

export const HomePageQuery = graphql`
  query HomePageQuery {
    DrupalParagraphs: allNodeLandingPage(
      filter: { title: { eq: "Homepage" } }
    ) {
      edges {
        node {
          title
          field_json_api_call_data {
            type
            block_title
            url
            block {
              body {
                value
              }
              use_frame_animation
            }
            view_more_link {
              title
              uri
            }
            additional_info {
              content_type
              fields_machine_names
              no_items_per_row
            }
            items {
              itemNode {
                title
                field_art_year
                field_artwork_name
                relationships {
                  field_artist_listing_image {
                    uri {
                      url
                    }
                  }
                  field_artist_photo {
                    uri {
                      url
                    }
                  }
                  field_artist_ref {
                    field_firstname
                    field_lastname
                    drupal_id
                    title
                    relationships {
                      field_artist_photo {
                        uri {
                          url
                        }
                      }
                      field_artist_listing_image {
                        uri {
                          url
                        }
                      }
                    }
                    path {
                      alias
                    }
                  }
                  field_artist_type {
                    name
                  }
                  field_artwork_images {
                    uri {
                      url
                    }
                  }
                  field_artwork_medium {
                    name
                  }
                  field_artwork_status {
                    name
                  }
                  field_artwork_type {
                    name
                  }
                  field_locations_ref {
                    title
                  }
                  field_featured_image {
                    uri {
                      url
                    }
                    image_style_uri {
                      featured_image_crop
                    }
                  }
                }
                field_firstname
                field_lastname
                field_artist_payment
                field_art_price_original {
                  currency_code
                  number
                }
                field_circa
                field_depth
                field_exceptional_artwork_sold
                field_featured_contemporary
                field_featured_historic
                field_featured_link_text
                field_featured_order
                field_order_exceptional_sale
                field_price_avail_on_request
                field_promoted_artwork
                field_publish_to_rss_feed
                field_published_sold_artwork
                field_sort_order
                field_width
                field_height
                field_year_born
                path {
                  alias
                }
                body {
                  processed
                }
                field_dates {
                  end_value
                  value
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }

    allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "gatsby-menu" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          drupal_parent_menu_item
          bundle
          drupal_id
          menu_name
        }
      }
    }
    footer_menu: allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "footer" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          bundle
          drupal_id
          menu_name
        }
      }
    }
    hero_images: allNodeArtwork(
      filter: { field_feature_on_homepage_hero: { eq: true } }
    ) {
      nodes {
        field_artwork_name
        path {
          alias
        }
        relationships {
          field_artwork_images {
            uri {
              url
            }
          }
          field_artist_ref {
            title
            relationships {
              field_artist_type {
                name
              }
            }
          }
        }
      }
    }
    promoted_artwork: allNodeArtwork(
      filter: { field_promoted_artwork: { eq: 1 } }
    ) {
      nodes {
        field_artwork_name
        path {
          alias
        }
        relationships {
          field_artwork_images {
            uri {
              url
            }
          }
          field_artwork_medium {
            name
          }
          field_artist_ref {
            title
            relationships {
              field_artist_type {
                name
              }
            }
          }
        }
      }
    }
    exhibitions: allNodeExhibition(limit: 5, sort: { fields: created }) {
      edges {
        node {
          relationships {
            field_artwork_ref {
              field_artwork_name
            }
          }
          path {
            alias
          }
          title
        }
      }
    }
    locations: allNodeLocation {
      edges {
        node {
          field_address {
            address_line1
            address_line2
            country_code
            locality
            postal_code
          }
          field_address_description
          field_address_short
          field_location_email
          field_location_fax_number
          field_location_phone_number
          title
          field_office_hours_description
        }
      }
    }
    virtual_tours: nodeInfoPage(title: { eq: "Virtual Tours" }) {
      body {
        value
      }
    }
  }
`;
