import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import ImageInView from '../../fx/animate/ImageInView';
import { getImageSizeWithCMS } from '../../helper/imageStyleSizes';

const HeroHalfImage = (props) => {
  const { data, path, title, device } = props;

  const [image, setImage] = React.useState(null);
  const [d, setData] = React.useState(null);
  const [t, setTitle] = React.useState(null);

  React.useEffect(() => {
    if (typeof window !== null) {
      const img = getImageSizeWithCMS(data.relationships?.field_artwork_featured_image, 'gatsby_landing_page_banner')
      setImage(img)
      setTitle(title)
      setData(data);
    }
  }, [])

  const graphqlData = useStaticQuery(graphql`
    query HeroHalfImageQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  return (
    <div className="heroHalf_image">
      <div className="heroHalf_wrap">
        <Link to={d?.path?.alias || null}>
          <ImageInView
            src={image}
          />
          <span className="heroHalf_artist">
            {d?.relationships?.field_artist_ref?.title}
          </span>
        </Link>
      </div>
      {path && t ? (
        <Link
          to={path}
          state={{ artist: d?.relationships?.field_artist_ref?.title }}
        >
          <span className="heroHalf_title h3">{t}</span>
        </Link>
      ) : null}
    </div>
  );
};

const HeroHome = ({
  dataOne,
  dataTwo,
  cropOne,
  cropTwo,
  titleOne,
  pathOne,
  titleTwo,
  pathTwo,
  device,
}) => (
  <React.Fragment>
    <div className="lt-hero-twoup-container">
      {dataOne ? (
        <div className={`lt-Left ${dataOne && dataTwo ? '' : 'lt-full'}`}>
          <HeroHalfImage
            data={dataOne}
            path={pathOne}
            title={titleOne}
            device={device}
          />
        </div>
      ) : null}
      {dataTwo ? (
        <div className={`lt-Right ${dataOne && dataTwo ? '' : 'lt-full'}`}>
          <HeroHalfImage
            data={dataTwo}
            path={pathTwo}
            title={titleTwo}
            device={device}
          />
        </div>
      ) : null}
    </div>
  </React.Fragment>
);

export default HeroHome;
